$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;
$comparecrm-default-checkbox-selected-bgcolor: #e8f3f8;
$comparecrm-default-checkbox-border-color: #16556d;
$comparecrm-radio-default-button-bgcolor: #005670;
$comparecrm-radio-default-hover-button-bgcolor: #e8f3f8;
$comparecrm-radio-default-button-text-color: #000;
$comparecrm-default-button-bgcolor: #dd6453;
$comparecrm-default-button-hover-bgcolor: #ea604d;
$comparecrm-default-button-text-color: #ffffff;
$comparecrm-form-border-color: #2e2e2e;

$comparecrm-progress-main-color: #16556d;
$comparecrm-why-us-title-color: #2e2e2e;
$comparecrm-why-us-item-title-color: #005670;
$comparecrm-why-us-item-brief-color: #747474;
$comparecrm-header-phone-color: #dd6453;
$comparecrm-footer-color: #000;
:export {
    progressColor: $comparecrm-progress-main-color;
    primaryColor: $comparecrm-radio-default-button-bgcolor;
    green: $businessphoneprices-green;
    whyUsTitle: $comparecrm-why-us-title-color;
    whyUsItemTitle: $comparecrm-why-us-item-title-color;
    whyUsItemBrief: $comparecrm-why-us-item-brief-color;
    headerPhoneColor: $comparecrm-header-phone-color;
}
